/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

@import "@angular/material/prebuilt-themes/indigo-pink.css";

*,
html {
  scroll-behavior: smooth;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* :root{
  --white:#FFF;
  --black:#000;
  --lite:rgba(255,255,255,0.6);
  --gray:rgba(1,1,1,0.6);
  --dark:#202124;
  --primary:#fdd9a9;
  --default_font:'Kanit', sans-serif;
}

::-webkit-scrollbar {
  height: 12px;
  width: 4px;
  background: var(--dark);
}

::-webkit-scrollbar-thumb {
  background: gray;
  -webkit-box-shadow: 0px 1px 2px var(--dark);
}

::-webkit-scrollbar-corner {
  background: var(--dark);
} */

/* * {
  box-sizing: border-box;
  padding:0;
  margin: 0;
  scroll-behavior: smooth;
} */

.pointer {
  cursor: pointer;
}

.ngx-charts-outer {
  width: 20%;
}

.ui-modal {
  min-width: 90% !important;
}

.ui-modal-header {
  font-weight: 500;
  border-bottom: 1px solid #e9ecef;
  background-color: #fff !important;
  padding: 1.4rem 1rem !important;
  color: #000 !important;
}
.ui-modal-footer {
  border-top: 1px solid #e9ecef;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 14px;
  padding: 1.4rem 1rem !important;
  display: flex;
}

.ui-controlbar,
.ui-titlebar {
  grid-gap: 15px;
}

.ui-icon {
  color: darkslategray !important;
  font-size: 19px !important;
}

/* kk */

.editor {
  border: 1px solid rgba(22, 24, 35, 0.12);
  border-radius: 3px;
}

.NgxEditor {
  min-height: 195px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: none !important;
}
.NgxEditor__MenuBar {
  /* border-bottom: 1px solid #ccc; */
  border-top: 1px solid rgba(22, 24, 35, 0.12);
  border-bottom: 1px solid rgba(22, 24, 35, 0.12);
}
.NgxEditor__Content {
  min-height: 215px;
}

.mat-dialog-container {
  padding: 0px !important;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

/* input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
} */

/* You can add global styles to this file, and also import other style files */
/* @import '~@angular/material/prebuilt-themes/indigo-pink.css'; */

.body {
  margin: 0;
  background-color: #e9ecef;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.cdk-overlay-container {
  overflow: scroll;
  z-index: 1000;
}

.mat-drawer-content {
  overflow-x: initial !important;
  overflow-x: inherit !important;
}

.overlay {
  position: absolute !important;
}
.owl-theme .owl-nav [class*="owl-"] {
  background: #007bff !important;
}

.pointcursor {
  cursor: pointer;
}

.alert-info {
  text-align: center !important;
  margin-bottom: 0 !important;
}

.cdk-virtual-scroll-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  contain: none !important;
}
.vjs-ended .vjs-replay-button {
  display: none !important;
}
/* .ghost {  position: absolute;  left: -999999px;} */

.example-chip-list {
  width: 100%;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgb(255 255 255);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgb(236 235 241);
}
.mat-form-field-label,
.mat-hint {
  color: #212529;
}

/* get rid of highlight text */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

select.form-control:not([size]):not([multiple]) {
  padding: 5px;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* ngx pag */
.text-center .ngx-pagination {
  padding-left: 0px !important;
}

.sidebar.open.over {
 
  position: relative;
  height: 100vh;
  top: 0px;
}

/* // hide nav in studio */

/* .StudioBodywrapper{
  margin-top: -148px!important;
}
.StudiocontainerRight{
z-index: 100;
} */

@media (max-width: 500px) {
  .mat-dialog-container {
    border-radius: 0px !important;
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.skeleton-video {
  width: 100%!important;
}

.video-js .vjs-big-play-button:before {
  content: "\f101";
  color: #908b8b;
  font-family: nuevo;
  font-size: 75px;
  line-height: 97px;
  padding-left: 10px;
}


.viewport .cdk-virtual-scroll-content-wrapper {
  height:auto;
  display: flex!important;
  }

  input:-webkit-autofill {
    background-color: transparent !important;
    box-shadow: 0 0 0px 1000px white inset !important;
}